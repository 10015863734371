<template>
	<div class="footers">
		<footer class="main-footer">
			<div class="row">
				<div class="columns column3">
					<div class="footer-logo" />
				</div>
				<div class="columns column3">
					<h4>{{ defaults[locale].website.restaurantName }}</h4>
					<p class="address">
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span>
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span>
						<span>{{ defaults[locale].website.country }}</span>
					</p>
					<p>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`" class="contact-link">
							<font-awesome-icon icon="fal fa-phone" size="1x" />
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
						<a :href="`mailto:${defaults[locale].website.email}`" class="contact-link">
							<font-awesome-icon icon="fal fa-envelope" size="1x" />
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
					<social-media :socials="socials" />
				</div>
				<div class="columns column3">
					<h4>{{ $t('quickLinks') }}</h4>
					<p>
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }}
						</nuxt-link>
					</p>
				</div>
				<div class="columns column3">
					<div v-parse-links v-html="defaults[locale].website.footerContent" />
				</div>
			</div>
		</footer>
		<footer class="author-footer">
			<div class="row">
				<div class="columns column12">
					<div class="border" />
					<p>
						<a
							href="https://www.becurious.com/"
							target="_blank"
							rel="noopener noreferrer"
							title="Hotel website design by becurious"
						>
							Website Design
						</a>
						<span>by:</span>
						<a href="https://www.becurious.com" target="_blank">
							<img src="~/assets/images/logo-becurious-white.png" loading="lazy" alt="Becurious logo" />
						</a>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu } = footer.value;
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

if (!defaults.value) {
	await fetchDefaults();
}
</script>

<style lang="scss" scoped>
.footers {
	color: var(--footer-color);
	background: var(--footer-background-color);

	a,
	span,
	:deep(a) {
		color: var(--footer-color);
		text-decoration: none;
	}

	h3,
	h4,
	:deep(h3),
	:deep(h4) {
		color: var(--footer-color);
		letter-spacing: 1px;
		margin: 0 0 20px;
	}

	h4,
	:deep(h4) {
		font-family: var(--body-font-family);
		font-size: calc(var(--body-font-size) + 3px);
	}
}

.main-footer {
	padding: 75px 0;

	p,
	:deep(p) {
		font-size: calc(var(--body-font-size) - 3px);
	}

	a,
	span,
	:deep(a) {
		display: block;
	}

	a:hover,
	:deep(a:hover) {
		text-decoration: underline;
	}

	.footer-logo {
		background: url('~/assets/images/logo-matahari-full-red.png') no-repeat center center;
		background-size: 242px;
		width: 242px;
		height: 88px;
		max-width: 100%;
		margin: 40px 0;
	}

	.contact-link {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		gap: 15px;
	}
}

.author-footer {
	font-size: 0.8em;
	padding: 5px 0;
	text-align: center;
	width: 100%;

	p {
		margin: 15px 0;
	}

	.border {
		background: rgba(255 255 255 / 25%);
		width: 100%;
		height: 1px;
	}

	img {
		max-width: 90px;
		margin: -1px 1px 3px 2px;
	}
}

@media (max-width: 1080px) {
	footer .columns {
		margin: 0 auto 20px;
	}
}

@media (max-width: 640px) {
	.main-footer {
		padding: 30px 5px;
		text-align: center;

		.footer-logo {
			margin: 0 auto 20px;
		}

		.contact-link {
			text-align: center;
			justify-content: center;
		}
	}
}
</style>
